<template>
	<div class="vesselcalls-map-control rounded elevation-1" id="vesselcalls-fullmap-control">
		<div class="d-flex justify-center">
			<v-btn text class="btndl maptools">
				<map-download-pdf :source="maptype"/>
			</v-btn>
			<v-btn text class="btnzoom pa-0" @click="zoomOut">
				<v-icon class="fw-500" size="14">fak fa-line-less-zoom</v-icon>
			</v-btn>
			<v-btn text class="btnlevel fs-regular fw-500 pa-0">{{ zoom }}</v-btn>
			<v-btn text class="btnzoom pa-0" @click="zoomIn">
				<v-icon class="fw-500" size="14">fak fa-line-more-zoom</v-icon>
			</v-btn>
		</div>
	</div>
</template>

<script>
//import constants from '@/utils/constants';

//import MapMeasureTool from '@/components/map/sections/map/subcomponents/MapMeasureTool';
import MapDownloadPdf from '@/components/map/sections/map/subcomponents/MapDownloadPDF';

export default {
	name: 'VesselCallsControlFullMap',
	components: {
		MapDownloadPdf
	},
	data() {
		return {
			zoom: this.initial_zoom,
			maptype: 'fullMap'
		};
	},
	props: ['initial_zoom', 'map', 'max_zoom', 'min_zoom'],
	mounted() {
	},
	methods: {
		zoomIn() {
			if (this.map) {
				const view = this.map.getView();
				if(this.zoom < this.max_zoom) {
					this.zoom = this.zoom + 0.5;
					view.animate({
						zoom: this.zoom,
						duration: 200,
					});
				}
			}
		},
		zoomOut() {
			if (this.map) {
				const view = this.map.getView();
				if(this.zoom > this.min_zoom) {
					this.zoom = this.zoom - 0.5;
					view.animate({
						zoom: this.zoom,
						duration: 200,
					});
				}
			}
		}
	}
};
</script>

<style lang="postcss" scoped>
.vesselcalls-map-control {
	position: absolute;
	bottom: 10px;
	right: 10px;
	z-index: 1000;
	background-color: white;
	border: 1px solid #ccc;
	height: 36px;
	padding: 3px;
}
.btnzoom {
	margin-top: 2px;
	width: 24px;
	min-width: 24px !important;
	height: 24px !important;
	background-color: var(--negative);
}
.btnlevel {
	margin-top: 2px;
	width: 32px;
	min-width: 24px !important;
	height: 24px !important;
	background-color: var(--negative);
	cursor: auto !important;
	font-family: var(--font-family-primary);

	&:hover {
		background: var(--negative) !important;
	}
	&:focus {
		background: var(--negative) !important;
	}
}

.btndl {
	min-width: 32px !important;
	width: 32px !important;
	height: 30px !important;
	padding: 0px !important;
	background-color: var(--negative);
}
</style>
