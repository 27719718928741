<template>
	<div style="height: 325px" id="div-map-container">
		<div v-if="!loading" ref="map" class="map">
			<MapControl :map="map" :initial_zoom="initial_zoom" :max_zoom="max_zoom" :min_zoom="min_zoom" ref="zoomControls"></MapControl>
			<v-btn id="zoom-out" text class="zoomout" @click="zoomOut">
				<v-icon class="fs-regular fw-500">fak fa-line-zoom-framing</v-icon>
			</v-btn>
		</div>
		<v-progress-circular
			class="map"
			v-if="loading"
			:size="30"
			indeterminate
			reactive
			color="primary"
			style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)"
		></v-progress-circular>
	</div>
</template>

<script>
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import Heatmap from 'ol/layer/Heatmap';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import MapControl from './AqVesselCallsControlMap.vue';
import { DragPan } from 'ol/interaction';

export default {
	name: 'VesselCallsMap',
	props: ['data'],
	components: {
		MapControl
	},
	data() {
		return {
			loading: true,
			map: null,
			initial_zoom: 12,
			max_zoom: 13.5,
			min_zoom: 10.5
		};
	},

	computed: {
		zoom: {
			get() {
				console.log(this.initial_zoom);
				if (!this.zoom) {
					return this.initial_zoom;
				}

				return this.zoom | 0;
			},
			set(value) {
				if (value > this.max_zoom || value < this.min_zoom) {
					return;
				}

				if (this.map) {
					this.map.setZoom(value);
				}
			}
		}
	},
	watch: {
		data: {
			handler(newData) {
				console.log('Map component data updated:', newData);
				if (newData.length > 0) {
					this.removeLoading();
					this.initMap();
					this.$nextTick(() => {
						this.$refs.map.addEventListener('wheel', this.handleMouseWheel);
						this.updateMap();
					});
					//this.$store.state.misc.loadingDialog = false;
				}
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
		initMap() {
			let lat = (this.$store.getters.getUserProperties.swlon + this.$store.getters.getUserProperties.nelon) / 2
			let lon = (this.$store.getters.getUserProperties.swlat + this.$store.getters.getUserProperties.nelat) / 2
			const map = new Map({
				target: this.$refs.map,
				controls: [],
				interactions: [new DragPan()],
				layers: [
					new TileLayer({
						source: new OSM()
					})
				],
				view: new View({
					center: fromLonLat([lon, lat]),
					zoom: this.initial_zoom,
					maxZoom: this.max_zoom,
					minZoom: this.min_zoom
				})
			});

			const heatmapLayer = new Heatmap({
				source: new VectorSource({
					features: this.generateHeatmapFeatures()
				}),
				blur: 8,
				radius: 5
			});
			map.addLayer(heatmapLayer);

			this.map = map;
		},
		calculateDistance(point1, point2) {
			const lonDiff = point2[0] - point1[0];
			const latDiff = point2[1] - point1[1];

			return Math.sqrt(lonDiff * lonDiff + latDiff * latDiff);
		},
		generateHeatmapFeatures() {
			const originalPoints = this.data;
			const features = [];
			const distanceThreshold = 0.015; // Cambiar esto si se crean lineas nuevas o no se crean (mayor = mas lineas)

			for (let i = 0; i < originalPoints.length - 1; i++) {
				const startPoint = originalPoints[i];
				const endPoint = originalPoints[i + 1];
				const distance = this.calculateDistance(startPoint, endPoint);

				if (distance < distanceThreshold) {
					const interpolationPoints = Math.floor(distance / 0.001);
					const interpolatedPoints = this.interpolatePoints(startPoint, endPoint, interpolationPoints);
					features.push(...interpolatedPoints);
				} else {
					features.push(new Feature({
						geometry: new Point(fromLonLat([startPoint[0], startPoint[1]])),
						value: startPoint[2]
					}));
					features.push(new Feature({
						geometry: new Point(fromLonLat([endPoint[0], endPoint[1]])),
						value: endPoint[2]
					}));
				}
			}
			return features;
		},
		interpolatePoints(startPoint, endPoint, numPoints) {
			const interpolatedPoints = [];
			const lonDiff = endPoint[0] - startPoint[0];
			const latDiff = endPoint[1] - startPoint[1];
			const valueDiff = endPoint[2] - startPoint[2];

			for (let i = 0; i <= numPoints; i++) {
				const lon = startPoint[0] + lonDiff * (i / (numPoints + 1));
				const lat = startPoint[1] + latDiff * (i / (numPoints + 1));
				const value = startPoint[2] + valueDiff * (i / (numPoints + 1));

				interpolatedPoints.push(
					new Feature({
						geometry: new Point(fromLonLat([lon, lat])),
						value: value
					})
				);
			}

			return interpolatedPoints;
		},
		destroyMap() {
			if (this.map) {
				this.map.setTarget(null);
				this.map = null;
			}
		},
		setZoom(zoom) {
			this.map.getView().setZoom(zoom);
		},
		removeLoading() {
			this.loading = false;
		},
		updateMap() {
			this.destroyMap();
			this.initMap();
		},
		zoomOut() {
			this.$emit('zoom-out');
		},
		handleMouseWheel(event) {
			event.preventDefault();
			if (event.deltaY < 0) {
				this.$refs.zoomControls.zoomIn();
			} else {
				this.$refs.zoomControls.zoomOut();
			}
		},
	}
};
</script>

<style scoped>
.map {
	width: 100%;
	height: 325px;
}
.zoomout {
	position: absolute;
	top: 2px;
	right: 2px;
	z-index: 3;
	min-width: 24px !important;
	height: 24px !important;
	padding: 0px !important;
	background-color: var(--negative);
}
</style>
